import * as React from "react";
import { useState } from "react";
import { callOfTheWild } from "../books/callOfTheWild.js";
import { prideAndPrejudice } from "../books/prideAndPrejudice.js";
import styled from "styled-components";

const matchPunctuation = new RegExp(/['";:?.,(){}[\]_-]/g);
// styles
const pageStyles = {
  color: "rgb(81 57 112)",
  background: "#fbf9f7",
  padding: "20px 80px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const punctuationParagraph = {
  width: "100%",
  display: "inline-block",
  margin: "10px 0",
  lineBreak: "anywhere",
};

const getMostCommonWords = (string) => {
  var wordCountList = {};

  string.split(" ").forEach(function (word) {
    if (word !== " ") {
      if (!wordCountList[word]) {
        wordCountList[word] = 1;
      }
      wordCountList[word]++;
    }
  });

  // Get an array of the keys:
  let keys = Object.keys(wordCountList);

  // Then sort by using the keys to lookup the values in the original object:
  keys.sort(function (a, b) {
    return wordCountList[b] - wordCountList[a];
  });
  return keys.slice(0, 5).join(", ");
};

const Button = styled.button`
  padding: 10px 60px;
  border-radius: 10px;
  border: 1px solid #513970;
  background: #7859a1;
  color: white;
  margin-top: 15px;
  cursor: pointer;
  transition: background 0.2s;

  :hover {
    background: #9775C7;
  }
`;

// markup
const IndexPage = () => {
  const [results, setResults] = useState(null);
  const [inputText, setInputText] = useState("");

  const handleAnalyse = () => {
    const resultsObject = {};
    resultsObject.wordCount = inputText.split(" ").length - 1;
    if (inputText.match(matchPunctuation)) {
      resultsObject.strippedPuncuation = inputText
        .match(matchPunctuation)
        .join("")
        .slice(0, 2000);
    }
    resultsObject.sentenceCount = inputText.split(". ").length - 1;
    resultsObject.avgWordsPerSentence = (
      resultsObject.wordCount / resultsObject.sentenceCount || 0
    ).toFixed(0);
    resultsObject.mostFrequentWords = getMostCommonWords(inputText);
    setResults(resultsObject);
  };

  return (
    <main style={pageStyles}>
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <h1 style={{ color: "rgb(81 57 112)" }}>Author Styles</h1>
        <h2 style={{ fontWeight: "300", marginBottom: "60px" }}>
          Find similar authors and books based on writing style using our
          comparison engine.
        </h2>

        <div
          style={{
            padding: "40px",
            boxShadow: "rgb(149 157 165 / 11%) 1px 7px 10px",
            margin: "40px 0",
            borderRadius: "20px",
            background: "white",
          }}
        >
          <h4 style={{ textDecoration: "underline", marginTop: "0" }}>
            The Call of the Wild, by Jack London
          </h4>
          <p style={punctuationParagraph}>Stripped puncuation:</p>
          <p style={punctuationParagraph}>
            {callOfTheWild.match(matchPunctuation).join("").slice(0, 2000)}
          </p>
          <div style={{ height: "20px" }} />
          <p style={punctuationParagraph}>
            • Word count: {callOfTheWild.split(" ").length}
          </p>
          <p style={punctuationParagraph}>
            • Sentence count: {callOfTheWild.split(". ").length}
          </p>
          <p style={punctuationParagraph}>
            • Average words per sentence:{" "}
            {(
              callOfTheWild.split(" ").length / callOfTheWild.split(". ").length
            ).toFixed(0)}
          </p>
          <p style={punctuationParagraph}>
            • Most frequent words: {getMostCommonWords(callOfTheWild)}
          </p>
        </div>

        <div
          style={{
            padding: "40px",
            boxShadow: "rgb(149 157 165 / 11%) 1px 7px 10px",
            margin: "40px 0",
            borderRadius: "20px",
            background: "white",
          }}
        >
          <h4 style={{ textDecoration: "underline", marginTop: "0" }}>
            Pride and Prejudice by Jane Austen
          </h4>
          <p style={punctuationParagraph}>Stripped puncuation:</p>
          <p style={punctuationParagraph}>
            {prideAndPrejudice.match(matchPunctuation).join("").slice(0, 2000)}
          </p>
          <div style={{ height: "20px" }} />
          <p style={punctuationParagraph}>
            • Word count: {prideAndPrejudice.split(" ").length}
          </p>
          <p style={punctuationParagraph}>
            • Sentence count: {prideAndPrejudice.split(". ").length}
          </p>
          <p style={punctuationParagraph}>
            • Average words per sentence:{" "}
            {(
              prideAndPrejudice.split(" ").length /
              prideAndPrejudice.split(". ").length
            ).toFixed(0)}
          </p>
          <p style={punctuationParagraph}>
            • Most frequent words: {getMostCommonWords(prideAndPrejudice)}
          </p>
        </div>

        <div
          style={{
            padding: "40px",
            boxShadow: "rgb(149 157 165 / 11%) 1px 7px 10px",
            margin: "40px 0",
            borderRadius: "20px",
            background: "white",
          }}
        >
          <h4 style={{ textDecoration: "underline", marginTop: "0" }}>
            Compare any other piece of writing
          </h4>
          ​
          {!results ? (
            <>
              <textarea
                style={{
                  width: "100%",
                  padding: "20px",
                  boxSizing: "border-box",
                  border: "1px solid #c5bdce",
                  borderRadius: "10px",
                  background: "rgb(253 252 255)",
                  fontFamily: "-apple-system, Roboto, sans-serif, serif",
                }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                id="textarea"
                placeholder="Paste text here to anaylise it"
                rows="10"
                cols="70"
              ></textarea>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => handleAnalyse()}
                >
                  ANALYSE
                </Button>
              </div>
            </>
          ) : (
            <>
              {!!results.strippedPuncuation && (
                <>
                  <p style={punctuationParagraph}>Stripped puncuation:</p>
                  <p style={punctuationParagraph}>
                    {results.strippedPuncuation}
                  </p>
                  <div style={{ height: "20px" }} />
                </>
              )}

              <p style={punctuationParagraph}>
                • Word count: {results.wordCount}
              </p>
              <p style={punctuationParagraph}>
                • Sentence count: {results.sentenceCount}
              </p>
              <p style={punctuationParagraph}>
                • Average words per sentence: {results.avgWordsPerSentence}
              </p>
              <p style={punctuationParagraph}>
                • Most frequent words: {results.mostFrequentWords}
              </p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => setResults(null)}
                >
                  TRY AGAIN
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
